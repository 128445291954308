import { getLs, storageKeys, getSs } from "@/utils/storage"
import { saveUtm } from "@/utils/utm"

const plugin: NuxtPlugin = ({ store, route }) => {
  // Имперсонализация
  store.commit("user/SET_PREVIOUS_TOKEN", getLs(storageKeys.previousToken))

  // Локальные настройки
  // store.dispatch("user/FETCH_PREFS")

  // Определяем показывать или нет кнопку "Назад"
  store.commit("app/SET_HISTORY_LEN", window.history.length)

  store.commit("app/SET_BANNER_CLOSED", getSs(storageKeys.bannerClosed) || false)

  saveUtm(route.query)
}
export default plugin
