import { wrap } from "../_"

const pfx = "/person"

export default (a: NuxtAxiosInstance) => ({
  getSpeakers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getSpeakerData: (speakerAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${speakerAlias}`,
      { cache: true, ...cfg })),

  getRegions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/filter/region`,
      { cache: true, ...cfg })),

  getThemes: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/filter/theme`,
      { cache: true, ...cfg })),
})
