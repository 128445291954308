import Vue from "vue"
import {
  Dialog,
  Tabs,
  TabPane,
  Notification,
  Autocomplete,
  Popover,
  Pagination,
} from "element-ui"

// https://element.eleme.io/#/en-US/component/quickstart#on-demand
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Autocomplete)
Vue.use(Popover)
Vue.use(Pagination)

Vue.prototype.$notify = Notification
