import type { Route } from "vue-router"
import { storageKeys, getLs, setLs, removeLs } from "./storage"

export function getUtm () {
  return getLs(storageKeys.utm)
}

export function saveUtm (query: Route["query"]) {
  if (!query) return

  const utms: string[] = []
  Object.entries(query).forEach(([k, v]) => {
    if (k.startsWith("utm_")) utms.push(`${k}=${encodeURIComponent(String(v))}`)
  })
  const utmString = utms.join("&").substring(0, 255)
  if (!utmString) return

  setLs(storageKeys.utm, utmString)
}

export function clearUtm () {
  removeLs(storageKeys.utm)
}
