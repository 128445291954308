export const actions = {
  async nuxtServerInit ({ dispatch, commit }) {
    // const votingDate = Date.UTC(2023, 2, 6, 3) // 2023-03-06T00:06:00+03:00
    // const votingEnabled = Date.now() >= votingDate
    commit("app/SET_VOTING_ENABLED", true)

    // const stage3Date = Date.UTC(2023, 3, 2, 21) // 2023-04-03T00:00:00+03:00
    // const stage3 = Date.now() >= stage3Date
    commit("app/SET_STAGE_3", true)

    const mapVisibleDate = Date.UTC(2023, 4, 14, 21) // 2023-05-15T00:00:00+03:00
    const mapVisible = Date.now() >= mapVisibleDate
    commit("app/SET_MAP_VISIBLE", mapVisible)

    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
