
import { mapState } from "vuex"
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  props: {
    white: { type: Boolean, default: false },
  },

  data: () => ({
    isMenuOpen: false,
  }),

  computed: {
    ...mapState("app", ["votingEnabled", "stage3"]),
  },

  watch: {
    $route: {
      handler () { this.isMenuOpen = false },
      deep: true,
    },

    isMenuOpen (isMenuOpen) {
      if (isMenuOpen) setTimeout(() => {
        (this.$refs.nav as HTMLElement)?.focus()
      }, 200)
      else setTimeout(() => {
        document.querySelector("a")?.focus()
      }, 200)
    },
  },
})
