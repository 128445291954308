
import { mapGetters } from "vuex"
import layoutMixin from "@/mixins/layout"

export default {
  mixins: [layoutMixin],

  middleware: "auth",

  computed: {
    // ...mapState("user", ["unreadMessagesCount"]),
    ...mapGetters("user", [
      "user",
      "isAdmin",
      "isStatisticVisible",
      "isEventsVisible",
      "isEventsApplicationVisible",
    ]),

    isPaymentVisible () {
      return this.isPayable
    },

    tabs () {
      const tabs = [
        "profile",
        // "calendar",
        // "chat",
        // "recommended",
        "form",
      ]

      // if (this.isPaymentVisible) tabs.push("payment")
      // if (this.isStatisticVisible)
      //   tabs = tabs.concat([
      //     "rating/regions",
      //     // "rating/russia",
      //   ])

      if (this.isEventsVisible) tabs.push("events")

      if (this.isEventsApplicationVisible) tabs.push("requests")

      return tabs
    },

    tab: {
      get () {
        const { tabs } = this
        return tabs.find(t => this.$route.path.includes(t)) || tabs[0]
      },
      set (tab) {
        // FIXME
        if (!this.$route.path.includes(tab))
          this.$router.push(this.localePath(`/user/${tab}`))
      },
    },
  },
}
