import { wrap } from "../_"
import type {
  CreateEventPayload,
  CreateEventRequestPayload,
  EventRequest,
  ExhibitionEvent,
} from "../../../types/api/exhibition"

type CreateAppData = any // TODO
type UpdateAppData = any // TODO

const pfx = "/exhibition"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/favorite`,
      { ...cfg })),

  addFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/application/${appId}/favorite`, {},
      { ...cfg })),

  removeFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/application/${appId}/favorite`,
      { ...cfg })),

  createApp: (data: CreateAppData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/application/my`, data,
      { ...cfg })),

  updateMyApp: (id: string, data: UpdateAppData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/application/my/${id}`, data,
      { ...cfg })),

  getMyApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/my`,
      { ...cfg })),

  getMyApp: (id: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/my/${id}`,
      { ...cfg })),

  getApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application`,
      { cache: true, ...cfg })),

  getApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/${appId}`,
      { cache: true, ...cfg })),

  getCities: (region?: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/city`,
      { params: { "filter[region]": region }, cache: true, ...cfg })),

  getRegions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/region`,
      { cache: true, ...cfg })),

  getCitiesStats: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/favorite/statistics`,
      { cache: true, ...cfg })),

  getTotalStats: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/application/favorite/statistics-total`,
      { cache: true, ...cfg })),

  createEvent: (event: CreateEventPayload, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/event`, event,
      { ...cfg })),

  getMyEvents: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/my`,
      { ...cfg })),

  getEvents: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event`,
      { ...cfg })),

  getEvent: (eventId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/${eventId}`,
      { ...cfg })),

  createEventRequest: (
    payload: CreateEventRequestPayload,
    cfg?: AxiosConfig,
  ) => wrap(
    a.$post(`${pfx}/holding`, payload,
      { ...cfg })),

  getMyRequests: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/holding/my`,
      { ...cfg })),

  getEventRequests: (eventId: ExhibitionEvent["id"], cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/${eventId}/holding`,
      { ...cfg })),

  setRequestState: (
    requestId: EventRequest["id"],
    state: EventRequest["state"],
    cfg?: AxiosConfig,
  ) => wrap(
    a.$post(`${pfx}/holding/${requestId}/state`, { state },
      { ...cfg })),

  getEventsCities: (region?: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/city`,
      { params: { "filter[region]": region }, cache: true, ...cfg })),

  getEventsRegions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/region`,
      { cache: true, ...cfg })),
})
