// Методы форматирования чаще всего зависимы от локали приложения,
// поэтому они инъектированы глобально, без явного её указания.

import { localize } from "./modules/localize"
import { md } from "./modules/md"
import { formatDate } from "@/utils/date"

const getFormatters = ({ app: { i18n }, store }: NuxtContext) => {
  return {
    localize: (item: any) => localize(i18n.locale, item),

    // TODO: заменить на Luxon
    date: (
      date: number,
      pattern: string,
      { local = false, asIs = false }: { local?: boolean, asIs?: boolean } = {},
    ) => formatDate(
      date,
      pattern,
      i18n.locale,
      local
        ? ""
        : asIs
          ? "UTC"
          : store.getters["app/timezone"],
    ),

    // Полное имя пользователя
    name: (user: any) => (["firstName", "lastName"] as const)
      .map(k => localize(i18n.locale, user[k])).filter(Boolean).join(" "),

    // Компания пользователя
    // company: (user: any) => user.attributes?.companyForBadge ||
    //   localize(i18n.locale, user.company),

    // Маркдаун
    md,
  }
}

const plugin: NuxtPlugin = (ctx, inject) => inject("fmt", getFormatters(ctx))
export default plugin

type Formatters = ReturnType<typeof getFormatters>
declare module "vue/types/vue" {
  interface Vue { $fmt: Formatters }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $fmt: Formatters }
  interface Context { $fmt: Formatters }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $fmt: Formatters }
}
