var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:_vm.$style.content},[_c('app-header',{attrs:{"white":_vm.settings.whiteHeader}}),_vm._v(" "),_c('main',{class:[
        'section',
        _vm.$style.main, {
          [_vm.$style.mainFullWidth]: _vm.settings.fullWidth,
          [_vm.$style.mainPaddingTop]: !_vm.settings.noPaddingTop,
        }
      ]},[_vm._t("default")],2),_vm._v(" "),_c('app-footer',{attrs:{"id":"footer"}})],1),_vm._v(" "),_c('div',{class:_vm.$style.socialBtnsWrapper},[_c('app-social-btns')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }